<template>
    <div class="xxx">
        <div class="banner">
            <div class="banner_left">
                <h3 style="color: #5e2691;">Introducción de nuestro grupo</h3>
            </div>

        </div>
        <div class="main">
            <div class="main_left">
                <h3>Nuestro equipo se centra en ofrecer Los mejores servicios financieros para contribuir en mayor medida al desarrollo humano y social.</h3>

            </div>
            <div class="main_right">
                <img src="../assets/img/item2.png" alt="">
            </div>
        </div>

        <div class="summary">
            <div class="summary_wz">
                <h1 style="color: #5e2691;">Nuestros productos</h1>
                <p>Soluciones financieras inteligentes que cambian la forma en que las personas entienden y gestionan sus finanzas, permitiéndoles aumentar su capacidad de ahorro y mantener un control total sobre sus gastos</p>
            </div>
            <div class="summary_b">
                    <div class="summary_box">
                        <div class="summary_box_left">
                            <a href="https://www.echoprestamo.com/" style="display: flex;align-items: center;"><img style="width: 40px;border-radius: 10px;margin-right: 20px;" src="../assets/img/app2logo.webp" alt=""><p class="font-size25px">ECHO PRESTAMO</p><img style="width: 50px;display: inline-block;margin-left: 20px" src="../assets/img/right.png" alt=""></a>
                            <p> Le ofrecemos servicios de préstamo en el menor tiempo posible y con la tecnología más avanzada para satisfacer sus necesidades financieras diversificadas y personalizadas.</p>
                            <a href="https://www.solrapido.com" style="display: flex;align-items: center;"><img style="width: 40px;border-radius: 10px;margin-right: 20px;" src="../assets/img/app1logo.webp" alt=""><p class="font-size25px">Sol Rápido Finanzas</p><img style="width: 50px;display: inline-block;margin-left: 20px" src="../assets/img/right.png" alt=""></a>
                            <p>Podemos darle un respiro de las molestias de los asuntos financieros y podemos hacer que sea más flexible y más fácil para usted para hacer frente a sus finanzas. El tiempo es sólo suyo.</p>
                        </div>
                        <div class="summary_box_right">
                            <div class="summart_box_right_img">
                                <img src="../assets/img/app1.webp" alt="">
                                <img src="../assets/img/app2.webp" alt="">
                            </div>
                        </div>
                    </div>
                
            </div>
            
        </div>
        <!-- <div class="part">
            <div class="part_top">
                <h1 style="color: #5e2691;">Dirección</h1>
            </div>
            <div class="part_nr">
                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people1.webp" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Roberto </h3>
                        <p>Co-fundador</p>
                    </div>
                </div>

                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people3.jpg" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Fernando </h3>
                        <p>Global Head of Customer Support</p>
                    </div>
                </div>
                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people8.webp" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Monica</h3>
                        <p>Head of Human Resources</p>
                    </div>
                </div>
                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people13.jpg" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Boris Batine</h3>
                        <p>Global Head of Corporate Finance</p>
                    </div>
                </div>
                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people14.jpg" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Daniel</h3>
                        <p>Head of Growth</p>
                    </div>
                </div>
                <div class="part_box">
                    <div class="part_box_img">
                        <img src="../assets/img/people/people15.jpg" alt="">
                    </div>
                    <div class="part_box_rw">
                        <h3>Osvaldo</h3>
                        <p>Head of Growth</p>
                    </div>
                </div>
            </div>
        </div> -->

        

    </div>
</template>

<script>
import Swiper from 'swiper'

export default {
    metaInfo : {
    title:'Sobre nosotros',
    meta:[
    {
      name:'description',
      content:'Soluciones financieras inteligentes que cambian la forma en que las personas entienden y gestionan sus finanzas'
    },
    {
        name:'description',
        content:' para satisfacer sus necesidades financieras diversificadas y personalizadas'
    },
    {
        name:'description',
        content:'podemos hacer que sea más flexible y más fácil para usted para hacer frente a sus finanzas'
    },
    {
        name:'Sobre nosotros | España',
        content:'Introducción de nuestro grupo'
    }
]
  },
    data() {
      return {
        activeName: 'second',
        //  bannerList: [require('@/assets/img/111.gif'),require('@/assets/img/box1.png'),require('@/assets/img/box2.png'),require('@/assets/img/box3.png')]
        bannerList:6
    };
    },
    mounted() {
    // 下面是普通swiper模板
    new Swiper(".swiper-container", {
        loop: true,
        mousewheel: true,
        keyboard: true,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 3                   
    });
},

    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style scoped lang="less">
@import url(../assets/css/index.css);

img {
    width: 100%;
    height: 100%;
    border: 0px;
}
hr {
    height: 1px;
    background-color: #ccc;
    border: 1px 0px solid #ccc;
}
.banner {
    margin-bottom: 0px;
    padding: 100px 150px;
    background-color: rgb(241, 244, 247);

}
.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(232, 237, 242);
    padding: 0px 0px 0px 150px;

    .main_left {
        text-align: left;
        width: 50%;

        h3 {
            font-size: 40px;
            padding-bottom: 30px;
        }

        p {
            font-size: 18px;
        }
    }

    .main_right {
        width: 700px;
        margin-left: 100px;
    }
}



.summary {
    background-color: #dfdfdf;
    .summary_wz {

        padding: 50px 300px;
        h1 {
        font-size: 50px;
        text-align: center;
    }
    p {
        font-size: 17px;
        padding-top: 30px;
    }
    
    }
    .summary_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5 ;
        padding: 150px 250px 150px 200px;
        color: #000;
        font-size: 20px;
        .summary_box_left {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 55%;
            a {
                display: inline;
                font-size: 40px;
                font-weight: 700;
                color: #5e2691;
                text-decoration: none;
            }
            p{
                font-size: 20px;
                padding: 30px 0px;
            }

        }
        .summary_box_right {
            .summart_box_right_img {
                display: flex;
                width: 300px;
                img {
                width: 200px;
                margin: 0px 10px;
               }
            }
        }
    }
}
.sli {
    font-size: 60px;
    margin-bottom: 10px;
    color: #5e2691;
}
.sli_box {
        text-align: left;
        padding-left: 20px;
        p {
        
            padding: 20px;
        }
        p::after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            background-color: rgba(10, 37, 64, 0.2);
            left: 0px;
            top: 75px;
        }
        p:nth-child(1)::before {
            content: "";
            width: 8px;
            height: 2px;
            position: absolute;
            background-color: rgba(10, 37, 64, 0.2);
            left: 0px;
            top: 110px;
        }
        p::before {
            content: "";
            width: 8px;
            height: 2px;
            position: absolute;
            background-color: rgba(10, 37, 64, 0.2);
            left: 0px;
            top: 180px;
            
        }
}
.sli_box::before {
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: absolute;
    background-color: rgb(10, 37, 64);
    left: -6px;
    top: 75px;
}

::v-deep .el-tabs__item.is-active{
    font-weight: 700;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep .el-tabs__item {
    font-size: 20px;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep el-tabs__active-bar{
    background-color: #fff !important;
}
::v-deep .el-tabs__nav-wrap::after {
    margin-left: 50%;
    transform: translateX(-50%);
}
::v-deep .el-tabs__nav-wrap  {
    margin: auto !important;
}
::v-deep .el-tabs__header {
    margin: auto !important;
    width: 30% !important;
}
.part {
    padding: 70px 150px;
    .part_top {
        padding: 30px 0px;
        h1 {
            font-size: 40px;
        }
    }
    .part_nr {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .part_box {
            width: 30%;
            display: flex;
            align-items: center;
            margin: 50px 10px;
            .part_box_img {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .part_box_rw {
                text-align: left;
                padding-left: 10px;
                h3 {
                    font-size: 23px;
                    padding-bottom: 40px;
                }
            }
        }
    }
}
</style>