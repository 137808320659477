<template>
  <div class="NavBox">
    <nav>
      <div class="nav_left">
        <div class="logo">
          <router-link class="nav_right_box" to="/index">
            <img style="width:50px;border-radius: 5px;" src="../assets/logo.png" alt="">
          </router-link>

        </div>
        <router-link to="/index"><p> INX SAC </p></router-link>
      </div>
      <div class="nav_right">
        <router-link class="nav_right_box" to="/Quienes">Sobre nosotros</router-link>

        <router-link class="nav_right_box" to="/about">Tecnología</router-link>
        <router-link class="nav_right_box" to="/Datos">Datos</router-link>
        <router-link class="nav_right_box" to="/Carreras">carreras</router-link>
      </div>
    </nav>
    
  </div>
</template>

<script>
export default {
  // name: 'NavBox',
}
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
p {
  
}
nav {
  z-index: 999;
  position: fixed;
  width: 100%;
  background-color: #fff;
  box-shadow: 5px 5px 10px #ccc ;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: transparent;
  .nav_left {
    display: flex;
    align-items: center;
    p {
      font-size: 25px;
      padding-left: 20px;
    }
    a {
      
      text-decoration: none;
    }
    .logo {
      width: 40px;
    }
  }
  .nav_right {
    display: flex;
    .nav_right_box {
      margin: 5px 15px;
      text-decoration: none;
    }

  }
}
</style>