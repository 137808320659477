<template>
    <div class="xxx">
        <div class="banner">
            <div class="banner_left">
                <h3 style="color: #5e2691;">Nuestra tecnología</h3>
            </div>
            <div class="banner_right">
                <img src="../assets/img/item3.png" alt="">
            </div>
        </div>
        <div class="action">
            <div class="action_wz">
                <h1>Plataforma independiente de financiación </h1>
                <h1>Sistema de inteligencia avanzado</h1>
                <h1>Sólido equipo de desarrollo</h1>
            </div>
            <div class="action_b">
                <div class="action_tot">
                    <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box4.png" alt=""></div>
                    <h3>Innovación</h3>
                    <p>Nuestro producto es una innovación en el sector financiero, especialmente en el ámbito de Fintech.</p>
                    </div>
                    <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box5.png" alt=""></div>
                    <h3>Eficiencia</h3>
                    <p>Nuestros productos se centran en la eficiencia, y nos comprometemos a ofrecer servicios financieros cómodos y personalizados a nuestros usuarios.</p>
                    </div>
                </div>
                <div class="action_tot">
                    <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box6.png" alt=""></div>
                    <h3>Facilidad</h3>
                    <p>Abogamos por la facilidad, con la esperanza de poder hacer más con menos y resolver los problemas más difíciles con las soluciones más sencillas.</p>
                    </div>
                    <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box7.png" alt=""></div>
                    <h3>Seguridad</h3>
                    <p>Nos centramos en la protección de los datos personales de nuestros usuarios y nos comprometemos a ser una plataforma fiable y digna de confianza.</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="joinus">
            <div class="joinus_left">
                <img src="../assets/img/item2.jpg" alt="">
            </div>
            <div class="joinus_right">
                <h3>¿Quieres participar en nosotros?</h3>
                <h4>Tenemos los puestos vacíos</h4>
                
            </div>
        </div>

    </div>
</template>
<script>
export default {
    metaInfo : {
    title:'Tecnología',
    meta:[
    {
      name:'description',
      content:'Plataforma independiente de financiación'
    },
    {
        name:'description',
        content:'Sistema de inteligencia avanzado'
    },
    {
        name:'description',
        content:'Sólido equipo de desarrollo'
    },
    {
        name:'Tecnología | España',
        content:'Nuestra tecnología'
    }
]
  },
}
</script>
<style scoped lang="less">

@import url(../assets/css/index.css);
img {
    width: 100%;
    height: 100%;
    border: none !important;
}
a {
    text-decoration: none;
}
.banner {
    padding: 70px 0px 0px 150px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 50px;
    text-align: left;
    background-color: #fff;
    margin-bottom: 0px;
    .banner_left {

    }
    .banner_right {
        width: 700px;
    }
}
.action {
    padding-top: 120px;
    background-color: rgb(232, 237, 242);
    .action_wz {
        h1,p {
            font-size: 40px;
            padding: 0px 20%;
            font-weight: 700;
        }
        p {
            color: rgb(145, 146, 148);
        }
    }
    .action_b {
        display: flex;
        flex-wrap: wrap;
        .action_tot {
            margin: 20px 0px;
            display: flex;
            justify-content: space-between;
            .action_box {
                margin: 20px 20px;
                img {
                    width: 60%;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 30px;
                    padding-bottom: 10px;
                    color: #5e2691 !important;
                }
                p {
                    font-size: 20px;
                    span {
                        color: #000;
                        font-weight: 700;
                    }
                }   
            }
        }
    }
}
.action {
    background-color: rgb(241, 244, 247);
    .action_wz {
        h1,p {
            font-size: 40px;
            padding: 0px 20%;
            font-weight: 700;
        }
        p {
            color: rgb(145, 146, 148);
        }
    }
    .action_b {
        display: flex;
        flex-wrap: wrap;
        .action_tot {
            margin: 20px 0px;
            display: flex;
            justify-content: space-between;
            .action_box {
                margin: 10px 20px;
                width: 45%;
                h3 {
                    font-size: 30px;
                    padding-bottom: 10px;
                    color: rgb(0, 94, 184);
                }
                p {
                    font-size: 20px;
                    span {
                        font-weight: 700;
                    }
                }   
            }
        }
    }
}

.siteinfo {
    background-color: #000;
    .siteinfo_top {
        padding: 70px 0px;
        h3 {
            font-size: 50px;
        }
        p {
            padding: 35px 0px;
            font-size: 20px;
        }
    }
    .siteinfo_nr {
        padding: 0px 150px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .siteinfo_box {
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #fff;
            // border-radius: 10px;
            // width: 200px;
            // height: 120px;
            padding: 5px;
            margin: 5px;
            .img_0 {
                
                width: 200px;
                img {
                    border-radius: 10px;
                    width: 100%;
            }
            }
            
            
        }
        .aaa {
                img {
                    height: 50%;
                }
        }
        .bbb {
                height: 100%;
        }
    }
}
.joinus {
    padding: 70px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fdfdfd;
    .joinus_left {
        width: 500px;
        
        img {
            border-radius: 10px;
        }
    }
    .joinus_right {
        text-align: left;
        font-size: 30px;
        width: 50%;
            h4 {
            color: #5e2691;
            padding: 40px 0px;
        }
        
        p {
            font-size: 20px;
        }
    }
}
</style>