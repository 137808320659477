import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Datos from '../views/Datos.vue'
import About from '../views/About.vue'
import Quienes from '../views/Quienes.vue'
import Carreras from '../views/Carreras.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/Index',
    name: 'Index',
    component: Index,
  },
  {
    path: '/Datos',
    name: 'Datos',
    component: Datos,       
  },
  {
    path:'/About',
    name:' About ',
    component: About
  },
  {
    path:'/Quienes',
    name:' Quienes ',
    component: Quienes
  },
  {
    path:'/Carreras',
    name:'Carreras',
    component: Carreras
  },
  {
    path: '',
    redirect:'/Index'
  },
]

const router = new VueRouter({
  routes,

})

export default router
