<template>
  <div id="app">
    <NavBox />
    <router-view></router-view>
    <Footer />
    </div>
</template>
<script>
import NavBox from './components/NavBox.vue'
import Footer from './components/Footer.vue'

export default {
  components:{
    NavBox,
    Footer,
  },
  name: 'App',
}
</script>
<style lang="less">
*{
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
strong,span {
  color: #5e2691;
}

nav {
  padding: 25px 150px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #5e2691;
    }
  }
}
::selection {
background-color: #1f6ada;
color: white;
}
</style>
