<template>
    <div class="xxx">
        <div class="banner">
            <div class="banner_left">
                <h3>Participa en nosotros</h3>
            </div>

        </div>
        <div class="main">
            <div class="main_left">
                <p>Aquí puedes: </p>
                <h3>hacer crecer tus talentos, ampliar tus horizontes y mejorar tus capacidades.</h3>
            </div>
            <div class="main_right">
                <img src="../assets/img/item16.png" alt="">
            </div>
        </div>

        <div class="summary">
            <div class="summary_wz">
                <h1>¿Por qué  INX SAC </h1>
            </div>
            <div class="summary_b">
                    <div class="summary_box">
                        <div class="summary_box_left">
                            <h3 style="color: #8f2aee;">Diversidad </h3>
                            <div class="summary_box_left_wz">
                                <div class="summary_box_left_wz1">
                                   <p>Somos un equipo diverso. Los miembros de nuestro equipo proceden de todo el mundo. Nuestro equipo es integrador, vibrante y dinámico. Podemos ser y desarrollar nuestro mismo.
Esta diversidad se refleja no sólo en la composición del equipo, sino también en el tratamiento de los asuntos. Nuestro programa escucha un amplio abanico de opiniones de los distintos miembros y busca puntos en común reservando las diferencias. En resumen, somos una gran familia con tolerancia y diversidad.</p>
                            </div>
                    </div>
                </div>
                <div class="summary_box_right">
                    <div class="summart_box_right_img">
                        <img src="../assets/img/item14.jpg" alt="">
                    </div>
                </div>
                    </div>

                
            </div>
            
        </div>
        <!-- <div class="photo">
            <h1>Perfiles de personal</h1>
            <div class="swiper-container" style="margin: 50px 20px;">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div style="margin: 40px 20px 0px;">
                        <img style="width: 100%;" src="../assets/img/people/people1.webp" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 170px 20px 0px;">
                        <img style="width: 50%;" src="../assets/img/people/people2.jpg" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 0px 20px 30px;">
                        <img style="width: 80%;" src="../assets/img/people/people3.jpg" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 0px 50px;">
                        <img style="width: 66%;" src="../assets/img/people/people4.jpeg" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 80px 20px 0px;">
                        <img style="width: 100%;" src="../assets/img/people/people5.jpeg" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 0px 20px;">
                        <img style="width: 50%;" src="../assets/img/people/people6.webp" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 0px 20px;">
                        <img style="width: 100%;" src="../assets/img/people/people7.webp" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 40px 20px 0px;">
                        <img style="width: 80%;" src="../assets/img/people/people8.webp" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 0px 20px 60px;">
                        <img style="width: 30%;" src="../assets/img/people/people9.jpg" alt="">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div style="margin: 20px 20px;">
                        <img style="width: 100%;" src="../assets/img/people/people10.webp" alt="">
                        </div>
                    </div>
                </div>
    

            </div>
        </div> -->


    </div>
</template>
<script>
import Swiper from 'swiper'
export default {
    
    metaInfo : {
    title:'carreras',
    meta:[
    {
      name:'description',
      content:'Diversidad'
    },
    {
        name:'description',
        content:'Conecta con nosotros'
    },

    {
        name:'carreras | España',
        content:'hacer crecer tus talentos, ampliar tus horizontes y mejorar tus capacidades'
    }
]
  },
    data() {
      return {
        activeName: 'first'
      };
    },
    mounted() {
    // 下面是普通swiper模板
    new Swiper(".swiper-container", {
        loop: true,
        mousewheel: true,
        keyboard: true,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 5               
    });
},
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    },
  };
</script>

<style scoped lang="less" >
@import url(../assets/css/index.css);
img {
    width: 100%;
    height: 100%;
}
.banner {
    padding: 150px 150px;
    margin-bottom: 0px;
}
.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(232, 237, 242);
    padding: 0px 0px 0px 150px;

    .main_left {
        text-align: left;
        width: 50%;
        

        h3 {
            font-size: 40px;
            padding-bottom: 10px;
        }

        p {
            color: #5e2691;
            font-size: 40px;
            font-weight: 700;
        }
    }

    .main_right {
        width: 700px;
    }
}
.action {
    background-color: rgb(237, 241, 245);
    .action_wz {
        margin: auto;
        text-align: center;
        width: 50%;
        padding: 70px 0px;
        h1 {
        font-size: 50px;
        text-align: center;
    }
    p {
        font-size: 17px;
        padding-top: 30px;
    }
    
    }
    .action_b {
        display: flex;
        margin-top: 70px;
        padding: 0px 150px 70px;
        .action_box {
            width: 30%;
            margin: auto;
            .action_img {
                margin: auto;
                width: 15%
            }
            h3 {
                font-size: 35px;
                padding: 15px 0px;
                color: #5e2691;
;
            }
            h4 {
                font-size: 18px;
                padding: 10px 0px;
            }
            p {
                font-size: 20px;
            }
        }
        .action_box:nth-child(2) {
            margin: 0px 30px;
        }
    }
    
}
.summary {
    background-color: rgb(39, 62, 86);
    .summary_wz {
        color: #fff;
        background-color: #2a2a2a;
        padding: 50px 300px;
        h1 {
        font-size: 40px;
        text-align: center;
    }
    p {
        font-size: 20px;
        padding-top: 30px;
    }
    
    }
    .summary_b {
        padding: 30px 0px;
        background-color: rgb(31, 31, 31);
    }
    .summary_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(31, 31, 31);
        padding: 0px 100px;
        color: #fff;
        font-size: 20px;
        .summary_box_left {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 70%;
            font-size: 45px;
                color: #fff;
                text-decoration: none;
            p{
                padding: 20px 0px;
            }
            .summary_box_left_wz {
                display: flex;
                justify-content: space-between;
                width: 70%;
                .summary_box_left_wz1 {
                    p {
                        font-size: 18px;
                    }
                }
                
            }
        }
        .summary_box_right {

            margin-left: 50px;
            .summart_box_right_img {
                width: 500px;
                img {
                    border-radius: 10px;
                }
            }
        }
    }
}
.photo {
    background-color: #fff;
    padding: 70px 150px;
    h1 {
        font-size: 40px;
    }
}
.title {
    background-color: #e5e5e5;
    padding: 70px 220px;
    color: #fff;
    h1 {
        margin-bottom: 70px;
        font-size: 50px;
    }

}
.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 100px;
    .item_left {
        
        .item_yh {
            width: 330px;
            height: 330px;
            border-radius: 50%;
            background-color: #475669;
            img {
                border-radius: 50%;
            }
        }
        
    }
    .item_right {
        text-align: left;
        width: 50%;
        h3 {
            color: #000;
        }
        h4 {
            color: #4d4d4d;
            font-size: 20px;
            padding: 30px 0px;
        }
        p {
            color: #5d5d5d;
        }
    }
}

::v-deep .el-tabs__item.is-active{
    font-size: 20px;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep .el-tabs__item {
    font-size: 20px;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep el-tabs__active-bar{
    background-color: #fff !important;
}
::v-deep .el-tabs__nav-wrap::after {
    margin-left: 50%;
    transform: translateX(-50%);
}
::v-deep .el-tabs__nav-wrap  {
    margin: auto !important;
}
::v-deep .el-tabs__header {
    margin: auto !important;
    width: 80% !important;
}


// 轮播图
::v-deep .el-carousel__item {
    background-color: #e5e5e5 !important;
}
::v-deep .el-carousel__item h3 {
    
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;

  }
  
  .el-carousel__item:nth-child(2n+1) {

    background-color: #d3dce6;
  }
  ::v-deep .el-carousel__arrow{
    color: #000;
    top: 36% !important;
    background-color: rgb(249 249 249 / 11%);
    
  }
</style>