<template>
    <div class="xxx">
        <div class="banner">
            <div class="banner_left">
                <h3 style="color: #5e2691;">Crédito personalizado</h3>

            </div>
            <div class="banner_right">
                <img src="../assets/img/item4.png" alt="">
            </div>
        </div>
        <div class="main">
            <div class="main_top">
                <h1>Nuestro servicio personalizado se basa en numerosos datos</h1>
                <p>Nos comprometemos a ofrecer soluciones personalizadas de servicios financieros a nuestros usuarios. Nuestras soluciones son inteligentes, eficientes y cómodas, y se basan en una recopilación de datos exhaustiva y científica.</p>
            </div>
            <div class="menu">
                <div class="menu_center">
                    <div class="box">
                    <h2><3 min</h2>
                    <p>El tiempo que llega en su cuenta</p>
                    </div>

                    <div class="box">
                    <h2>99%</h2>
                    <p>Tasa de aprobación</p>
                    </div>
                </div>
                <div class="menu_center">
                <div class="box">
                    <h2>30%</h2>
                    <p>Crecimiento de ingresos interanual 2022</p>
                </div>

                <div class="box">
                    <h2>77</h2>
                    <p>NPS de servicios en América Latina</p>
                </div>
                </div>
            </div>
        </div>
        <div class="test">
            <h1>¿Qué hemos hecho?</h1>
        </div>
        <div class="summary">
            <div class="summary_wz">
                <h1 style="color: #8520e3;">Scoring inteligente</h1>
                <p>Utilizamos inteligencia artificial que nos permite construir, probar y validar modelos avanzados de calificación crediticia.</p>
            </div>
            <div class="summary_b">
  
                    <div class="summary_box">
                        <div class="summary_box_left">
                            <a href="#">Recogida de datos científicos</a>
                            <div class="summary_box_left_wz">
                                <div class="summary_box_left_wz1">
                                   <p>Llevamos a cabo una recopilación de información meticulosa y científica. Filtramos la información perjudicial y falsa y nos comprometemos a prestar servicios financieros fiables y a eliminar la posibilidad de fraude.</p>
                                   <p></p>
                            </div>
                    </div>
                    </div>
                <div class="summary_box_right">
                    <div class="summart_box_right_img">
                        <img src="../assets/img/item5.png" alt="">
                    </div>
                </div>
                    </div>

                
            </div>
            
        </div>

        <div class="msg">
            <div class="msg_img" style="width: 400px;">
                <img src="../assets/img/item10.png" alt="">
            </div>
            <div class="msg_wz">
                <h3 style="color: #911cff;">Atención al Cliente inteligente</h3>
                <p>Disponemos de un servicio de Atención al Cliente 24 horas, tanto en línea como fuera de línea. Nuestro servicio de Atención al Cliente se basa en big data para ofrecer a los usuarios servicios especializados, personalizados y únicos.</p>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    metaInfo : {
    title:'Datos',
    meta:[
    {
      name:'description',
      content:'Recogida de datos científicos'
    },
    {
        name:'description',
        content:'Atención al Cliente inteligente'
    },
    {
        name:'description',
        content:'Nuestro servicio personalizado se basa en numerosos datos'
    },
    {
        name:'Datos | España',
        content:'Crédito personalizado'
    }
]
  },
    data() {
      return {
        activeName: 'first'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style scoped lang="less">
@import url(../assets/css/index.css);
img {
    width: 100%;
    height: 100%;
}
.box > h2 {
    color: #5e2691;
}
.banner {
    background-color: #fff;
    padding: 0px 0px 0px 150px;
    margin-bottom: 0px;
    .banner_left {

    }
    .banner_right {
        width: 700px;
        
    }
}
.main {
    background-color: rgb(232, 237, 242);
    padding: 70px 100px;
    .main_top {
        width: 50%;
        margin: auto;
        h1 {
            color: #5e2691;
            font-size: 50px;
            padding-bottom: 40px;
        }
        p {
            font-size: 17px;
            span {
                color: #000;
                font-weight: 700;
            }
        }
    }
}
.menu_center {
        margin: 100px 200px;
        display: flex;
        justify-content: space-between;
        .box {
            width: 40%;
        h2 {
            font-size: 55px;
            padding-bottom: 20px;
        }
        p {
            color: #000;
            font-size: 20px;
        }
    }
}
.test {
    padding: 70px 0px;
    font-size: 25px;
    background-color: #fdfdfd;
}
.summary {
    background-color: #fafafa;
    .summary_wz {
        background-color: #000;
        padding: 50px 300px;
        color: #fff;
        h1 {
        font-size: 40px;
        text-align: center;
    }
    p {
        font-size: 20px;
        padding-top: 30px;
    }
    
    }
    .summary_b {
        background-color: #272727;
    }
    .summary_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #515151;
        padding: 100px 200px;
        font-size: 20px;
        color: #fff;
        .summary_box_left {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 50%;
            a {
                font-size: 45px;
                font-weight: 700;
                color: #911cff;
                text-decoration: none;
            }
            p{
                padding: 20px 0px;
            }
            .summary_box_left_wz {
                display: flex;
                justify-content: space-between;
                width: 70%;
                .summary_box_left_wz1 {
                    p {
                        font-size: 18px;
                    }
                }
                
            }
        }
        .summary_box_right {
            .summart_box_right_img {
                width: 400px;
            }
        }
    }
}
.msg {
    padding: 70px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(96, 96, 96);
    color: #fff;
    .msg_wz {
        text-align: left;
        width: 50%;
        h3 {
            font-size: 40px;
        }
        p {
            strong {
                color: #911cff;
            }
            padding-top: 30px;
        }
    }
}

::v-deep el-tabs__active-bar{
    background-color: #fff !important;
}
::v-deep .el-tabs__nav-wrap::after {
    margin-left: 50%;
    transform: translateX(-50%);
}
::v-deep .el-tabs__nav-wrap  {
    margin: auto !important;
}

</style>