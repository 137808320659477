<template>
    <div class="xxx">
        <div class="banner" >

            <h3>Plataforma de Servicio de Fintech Internacional</h3>
        </div>
        <div class="miu">
            <p class="miu_p"><strong>Más Popular</strong>Somos la APP de préstamos más popular de América Latina.</p>
            
            <p class="miu_p"><strong>Mejor Servicio</strong>Ofrecemos los mejores servicios de crédito</p>

        </div>
        <div class="action">
            <div class="action_wz">
                <h1>El estilo  INX SAC </h1>
                <p>Estamos concentrado un desarrollo avanzado en el ámbito de Fintech con soluciones perfectas.</p>
            </div>
            <div class="action_b">
                <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box1.png" alt=""></div>
                    <router-link to="/Datos"><h3>Promover el desarrollo humano</h3></router-link>
                    <p>Nuestros productos y servicios satisfacen las necesidades de desarrollo humano y se comprometen a promoverlo.</p>
                </div>
                <div class="action_box" style="margin: 0px 50px;">
                    <div class="action_img"><img src="../assets/img/box/box2.png" alt=""></div>
                    <router-link to="/about"><h3>Promover el desarrollo social</h3></router-link>
                    <p>Nos centramos en el uso de Fintech para impulsar los flujos mundiales de capital y maximizar el uso de los recursos sociales.</p>
                </div>
                <div class="action_box">
                    <div class="action_img"><img src="../assets/img/box/box3.png" alt=""></div>
                    <h3>Rromover el desarrollo tecnológico</h3>
                    <p>En el ámbito de Fintech, nuestros productos son actuales, inteligentes y contribuyen al desarrollo de la tecnología.</p>
                </div>
            </div>
        </div>
        <div class="summary">
            <div class="summary_wz">
                <h1>Nuestros servicios</h1>
                <p>Nuestros servicios son sostenibles e inteligentes. Nos comprometemos a ofrecer soluciones más perfectas para cada individuo e incluso para la sociedad.</p>
            </div>
            <div class="summary_b">
                <div class="summary_box">
                    <div class="summary_box_left">
                        <a href="https://www.echoprestamo.com/" style="display: flex;align-items: center;"><img style="width: 40px;border-radius: 10px;margin-right: 20px;" src="../assets/img/app2logo.webp" alt=""><h5 style="font-size: 25px;">ECHO PRESTAMO</h5><img style="width: 50px;display: inline-block;margin-left: 20px" src="../assets/img/right.png" alt=""></a>
                        <p> Le ofrecemos servicios de préstamo en el menor tiempo posible y con la tecnología más avanzada para satisfacer sus necesidades financieras diversificadas y personalizadas.</p>
                        <a href="https://www.solrapido.com" style="display: flex;align-items: center;"><img style="width: 40px;border-radius: 10px;margin-right: 20px;" src="../assets/img/app1logo.webp" alt=""><h5 style="font-size: 25px;">Sol Rápido Finanzas</h5><img style="width: 50px;display: inline-block;margin-left: 20px" src="../assets/img/right.png" alt=""></a>
                        <p>Podemos darle un respiro de las molestias de los asuntos financieros y podemos hacer que sea más flexible y más fácil para usted para hacer frente a sus finanzas. El tiempo es sólo suyo.</p>
                    </div>
                    <div class="summary_box_right">
                        <div class="summart_box_right_img">
                            <img src="../assets/img/app1.webp" alt="">
                            <img src="../assets/img/app2.webp" alt="">
                        </div>
                    </div>
                </div>
            
        </div>
            
        </div>

    </div>
</template>
<script>
export default {
    metaInfo : {
    title:' INX SAC ',
    meta:[
    {
      name:'description',
      content:'Somos la APP de préstamos más popular de América Latina.'
    },

    {
        name:'description',
        content:'Ofrecemos los mejores servicios de crédito'
    },
    {
        name:' INX SAC  | España',
        content:'Plataforma de Servicio de Fintech Internacional'
    }
]
  },
    data() {
      return {
        activeName: 'second'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style lang="less" scoped>
@import url(../assets/css/index.css);
img {
    width: 100%;
    height: 100%;
}
h3  {
    color: #5e2691;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff; 
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: #e9e8e8; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}




.banner {
    margin-bottom: 0px;
    padding: 0px;
    background-image: url(../assets/img/nav.jpg);
    width: 100%; 
    height: 650px;
    background-size: 100%;
    h3 {
        color: #fff;
        width: 50%;
        padding-left: 150px;

    }
}
.miu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: rgb(248, 250, 251);
    overflow-x: scroll;
    overflow-y: hidden;
    .miu_p {
        white-space:nowrap;
        padding: 0px 15px;
        font-size: 14px;
        strong {
            padding-right: 10px;
        }
    }
}
.action {
    .action_wz {
        margin: auto;
        text-align: center;
        width: 50%;
        padding: 70px 0px;
        h1 {
        font-size: 50px;
        text-align: center;
    }
    p {
        font-size: 17px;
        padding-top: 30px;
    }
    
    }
    .action_b {
        display: flex;
        margin-top: 70px;
        padding: 0px 150px 70px;
        .action_box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .action_img {
                margin: auto;
                width: 10%
            }
            h3 {
                font-size: 30px;
                padding: 15px 0px;

            }
            p {
                font-size: 17px;
            }
        }
        .action_box:nth-child(2) {
            margin: 0px 30px;
        }
    }
    
}
.summary {
    background-color: #dfdfdf;
    .summary_wz {
        color: #000;
        padding: 50px 300px;
        h1 {
        font-size: 50px;
        text-align: center;
    }
    p {
        font-size: 17px;
        padding-top: 30px;
    }
    
    }
    .summary_b {
    }
    .summary_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5 ;
        padding: 150px 250px 150px 200px;
        color: #000;
        font-size: 20px;
        .summary_box_left {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 55%;
            a {
                display: inline;
                font-size: 40px;
                font-weight: 700;
                color: #5e2691;
                text-decoration: none;
            }
            p{
                font-size: 20px;
                padding: 30px 0px;
            }

        }
        .summary_box_right {
            width: 300px;
            border-radius: 10px;
            .summart_box_right_img {
                display: flex;
               border-radius: 10px;
               img {
                width: 200px;
                margin: 0px 10px;
               }
            }
        }
    }
}

::v-deep .el-tabs__item.is-active{
    font-size: 20px;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep .el-tabs__item {
    font-size: 20px;
    color: #5e2691 !important;
    font-weight: 700 !important;
}
::v-deep el-tabs__active-bar{
    background-color: #5e2691 !important;
}
::v-deep .el-tabs__nav-wrap::after {
    margin-left: 50%;
    transform: translateX(-50%);
}




</style>