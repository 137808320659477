import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入 swiper.css
import "swiper/css/swiper.css";

// 打包
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: function (h) { return h(App) },
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
